import { PropsWithChildren, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useFeature } from '../../../utils/feature-flags';
import { AnyObject } from '../../hooks/use-record-state';
import { useFormContext } from '../form-data-provider';
import {
  HandleSetPortalProgressionOptions,
  StepWithPath,
} from '../step-navigation';
import { useStepsStore } from '../step-navigation/steps-store-provider';
import { getStepIdentifier } from '../step-navigation/utils/get-step-identifier';

interface PortalProgressionHandlerProps<TFormData extends AnyObject> {
  onSetPortalProgression?: ({
    currentStepIdentifier,
    formData,
    lastCompletedStepNumber,
  }: HandleSetPortalProgressionOptions<TFormData>) => Promise<void>;
  steps: StepWithPath<TFormData>[];
}

export const PortalProgressionHandler = <TFormData extends AnyObject>({
  children,
  onSetPortalProgression,
  steps,
}: PropsWithChildren<Readonly<PortalProgressionHandlerProps<TFormData>>>) => {
  const isAdminPortalPersistenceEnabled = useFeature('adminPortalPersistence');

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { formData } = useFormContext();
  const { currentStepNumber } = useStepsStore();

  const persistPortalProgression = useDebouncedCallback(
    (currentStepIdentifier, formData, currentProgressionStepNumber) => {
      if (!onSetPortalProgression) {
        return;
      }

      const currentStep = steps[currentStepNumber - 1];

      void onSetPortalProgression({
        currentStep,
        currentStepIdentifier,
        formData,
        // TODO: This should be the last completed step number. Will be set properly in ADMIN-464
        lastCompletedStepNumber: currentProgressionStepNumber,
      });
    },
    2000,
    {
      leading: true,
    },
  );

  useEffect(() => {
    if (!isAdminPortalPersistenceEnabled) {
      return;
    }

    // Avoid re-saving the portal progression on initial load
    if (isInitialLoad) {
      setIsInitialLoad(false);

      return;
    }

    const currentStep = steps[currentStepNumber - 1];

    if (!currentStep) {
      return;
    }

    const currentStepIdentifier = getStepIdentifier(
      currentStepNumber,
      currentStep.title,
    );

    if (!currentStepIdentifier) {
      return;
    }

    persistPortalProgression(
      currentStepIdentifier,
      formData,
      currentStepNumber,
    );
    // Excluding isInitialLoad from the dependency array to avoid setting the progression
    // when initial load is set to false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepNumber, formData, persistPortalProgression, steps]);

  return <>{children}</>;
};
