import { ConnectionFragment } from '../../../../graphql/generated';
import { AnyObject } from '../../../hooks/use-record-state';
import { graphql } from '../../../utils/graphql';

export const setConnectionPortalProgression = async <
  TFormData extends AnyObject,
>({
  connection,
  currentStepIdentifier,
  formData,
  lastCompletedStepNumber,
}: {
  connection: ConnectionFragment;
  currentStepIdentifier: string;
  formData: TFormData;
  lastCompletedStepNumber: number;
}) => {
  await graphql().SetConnectionPortalProgression({
    input: {
      connectionId: connection.id,
      currentStepIdentifier,
      lastCompletedStepNumber,
      formData: Object.keys(formData).length > 0 ? formData : undefined,
    },
  });
};
